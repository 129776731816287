<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>

            <li>
              <router-link :to="{ name: 'company.contents.all' }">{{ this.$t('general.contents') }}</router-link>
            </li>
            <li>İçeriği Güncelle</li>
          </ul>
        </nav>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left">
            <span>İçeriği Güncelle</span>
          </h2>
        </div>
      </div>
      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red"><i class="uil-exclamation-triangle"></i> {{ error }}</p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <template v-if="assignments">
        <div class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red"><i class="uil-exclamation-triangle"></i> Atanması yapılmış içerikler güncellenemez.</p>
        </div>
      </template>

      <div v-if="loading">
        <default-loading></default-loading>
      </div>

      <div class="card" v-else>
        <ul class="uk-child-width-expand uk-tab stepper-buttons" id="uk-switcher-tab"
          uk-switcher="connect: #course-edit-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
          <li class="uk-active">
            <a class="p-4" href="#" aria-expanded="true"><i class="icon-feather-flag"></i>
              <b>{{ this.$t('messages.login') }}</b></a>
          </li>
          <li class="">
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-book-open"></i>
              <b>{{ this.$t('messages.content') }}</b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-cog"></i> <b>Özellikler</b></a>
          </li>
          <li>
            <a class="p-4" href="#" aria-expanded="false"><i class="uil-invoice"></i> <b>Sonuç</b></a>
          </li>
        </ul>

        <form class="card-body">
          <ul class="uk-switcher uk-margin" id="course-edit-tab" style="touch-action: pan-y pinch-zoom">
            <!--tab: özellikler-->
            <li class="uk-active" style="">
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="course_title">{{
                      this.$t('collectionDetail.content_name')
                    }}<span class="required">*</span></label>
                    <div class="col-md-9">
                      <input @input="handleTitleChanges" v-model="item.title" type="text" class="form-control"
                        id="course_title" name="title" placeholder="Başlık" required="" />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="code">{{ this.$t('general.content_code') }}</label>
                    <div class="col-md-9">
                      <input type="number" class="form-control" id="code" name="code" :value="item.code"
                        placeholder="99999999" readonly />
                    </div>
                  </div>

                  <div class="form-group row domain mb-3">
                    <label class="col-md-3 col-form-label" for="duration">Süre<span class="required">*</span></label>
                    <div class="col-md-9">
                      <div class="d-flex">
                        <input type="number" class="form-control flex-grow-1" id="duration" v-model="item.duration"
                          placeholder="0" required="" /><span>{{ $t("general.minutes") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="short_description">Açıklama</label>
                    <div class="col-md-9">
                      <textarea name="short_description" id="short_description" class="form-control"
                        v-model="item.description"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="short_description">Kategori<span
                        class="required">*</span></label>
                    <div class="col-md-9">
                      <select class="selectpickerContentCategory" name="content_category_id"
                        v-model="item.content_category_id">
                        <option value="">Kategori Türünü Seçin</option>
                        <option v-for="item in formattedContentCategories" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="logo">Kapak Görseli<span
                        class="required">*</span></label>
                    <div class="col-md-9">
                      <div class="position-relative d-flex justify-center justify-content-center">
                        <img style="height: 190px" class="rounded img-fluid logoarea" :src="item.cover_photo_link"
                          alt="" />
                        <input type="file" class="d-none" id="logo" accept="image/*" @change="processFile($event)" />
                        <button @click="inputClick()" type="button" class="btn btn-default btn-icon-label"
                          style="top: 50%; position: absolute">
                          <span class="btn-inner--icon">
                            <i class="uil-image"></i>
                          </span>
                          <span class="btn-inner--text">{{
                            $t("general.image_change")
                          }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="typo__label">Kategori Etiketleri<span class="required"></span>
                      <i class="uil-info-circle ml-1"
                        uk-tooltip="title: Yalnızca alfasayısal, kısa çizgiler ve alt çizgilere izin verilir.; pos: right"
                        aria-expanded="false"></i></label>
                    <multiselect v-model="hashtags" :tag-placeholder="$t('general.add_tag')"
                      :placeholder="$t('general.add_tag')" label="name" track-by="id" :selectedLabel="'Seçilmiş'"
                      :selectLabel="'Seçmek için enter\'a basın'" :deselectLabel="'Kaldırmak için enter\'a basın'"
                      :selectGroupLabel="'Grup seçmek için enter\'a basın'"
                      :deselectGroupLabel="'Grubun seçimini kaldırmak için enter\'a basın'" :options="hashtags_options"
                      :multiple="true" :taggable="true" :openDirection="'bottom'" @tag="addTag" @change="(val) => {
                        hashtags = val;
                      }
                        " @close="isClosedHashtagSelector = true" @open="isClosedHashtagSelector = false">
                      <template slot="noOptions"><span>Veri bulunmuyor</span></template>
                    </multiselect>
                    <!--                    <span class="typo__label form__label text-warning mt-1 ml-1" v-show="isEmptyHashtags()">En az birdeğer seçmelisiniz.</span>-->
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(1)">
                  <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                </button>
              </div>
            </li>
            <!--tab: icerik olustur-->
            <li class="" style="">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row mb-3" v-if="item.file_category_id && +item.file_category_id === 1">
                    <label class="col-md-4 col-form-label" for="use_content_point">
                      Scorm İçerik Skorunu Kullan
                    </label>
                    <div class="col-md-8">
                      <label class="uk-switch" for="use_content_point">
                        <input type="checkbox" id="use_content_point" v-model="item.use_content_point" />
                        <div class="uk-switch-slider"></div>
                      </label>
                    </div>
                  </div>

                  <div class="form-group row mb-3" v-if="!item.use_content_point">
                    <label class="col-md-4 col-form-label" for="score">
                      İçerik Skoru <span class="required">*</span>
                    </label>
                    <div class="col-md-8">
                      <input type="number" class="form-control" id="score" min="1" max="100" v-model="item.point"
                        placeholder="0" required="" />
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group row mb-2 input-checkbox">
                    <div class="col-md-4">
                      <label class="col-form-label mb-1" for="pass">İçerik Türü<span class="required">*</span></label>
                    </div>
                    <div class="col-md-8">
                      <select v-model="item.file_category_id" class="selectpickerFileCategory" name="">
                        <option value="">İçerik Türünü Seçin</option>
                        <option v-for="formattedFileType in formattedFileTypes" :key="formattedFileType.id"
                          :data-icon="formattedFileType.icon" :value="formattedFileType.id"
                          :selected="formattedFileType.id == item.file_category_id">
                          {{ $t(formattedFileType.key) }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-12" v-if="item.file_category_id">
                  <h4 class="text-center mt-4">
                    {{ $t(selected_content_type.name) }} Yükle <span class="required">*</span>
                  </h4>
                  <div v-if="selected_content_type.accept == 'id'">
                    <div class="form-group">
                      <input type="number" v-model="item.url" placeholder="ID" min="0" class="form-control" />
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.accept == 'url'">
                    <div class="form-group">
                      <input type="text" v-model="item.url" placeholder="https://www.example.com/document"
                        class="form-control" />
                    </div>
                  </div>

                  <div v-else-if="selected_content_type.accept === 'form' &&
                    selected_content_type.key === 'documents.classroom_education'">

                    <div class="form-group">
                      <form class="uk-child-width-1-3@s uk-grid-small p-4" uk-grid>
                        <div>
                          <h5 class="uk-text-bold mb-2">Eğitmen Adı<span class="required">*</span></h5>
                          <!-- <input type="text" v-model="item.instructor_name" class="form-control"> -->
                          <multiselect v-model="item.instructor_name" :allow-empty="true" :multiple="false"
                            :options="instructorNames" :searchable="true" :tag-placeholder="$t('general.select')"
                            :placeholder="$t('general.select')" selectLabel="Seçmek için enter'a basın"
                            deselectLabel="Kaldırmak için enter'a basın" :openDirection="'bottom'" :taggable="true"
                            @tag="addInstructorName">

                            <template slot="noOptions"><span>Veri bulunmuyor</span></template>
                          </multiselect>
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Konaklama Maliyeti</h5>
                          <input type="number" v-model="item.accommodation_cost" min="0" step="any"
                            class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Eğitim Yeri<span class="required">*</span></h5>
                          <multiselect v-model="item.education_place" :allow-empty="true" :multiple="false"
                            :options="educationPlaces" :searchable="true" deselectLabel="" selectLabel="Seçiniz"
                            :tag-placeholder="$t('general.select')" :placeholder="$t('general.select')" :taggable="true"
                            @tag="addEducationPlace">

                            <template slot="noOptions"><span>Veri bulunmuyor</span></template>
                          </multiselect>
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Eğitim Maliyeti</h5>
                          <input type="number" v-model="item.education_cost" min="0" step="any" class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Ulaşım Maliyeti</h5>
                          <input type="number" v-model="item.transportation_cost" min="0" step="any"
                            class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Eğitim Yeri Maliyeti</h5>
                          <input type="number" v-model="item.education_place_cost" min="0" step="any"
                            class="form-control" />
                        </div>
                        <div>
                          <h5 class="uk-text-bold mb-2">Kapasite</h5>
                          <input type="number" v-model="item.capacity" min="0" class="form-control" />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.check_list_item'">
                    <div class="form-group">
                      <h5 class="uk-text-bold mb-2">Açıklama Metni <span class="required">*</span>
                      </h5>
                      <textarea name="short_description" id="short_description"
                        v-model="item.content_check_list_items[0].description" class="form-control"></textarea>
                      <form class="mt-4" v-for="(subItem, index) in item.content_check_list_items">
                        <h5 class="uk-text-bold mb-2"><i class="uil-check-square"></i> {{ index + 1 }}. Kontrol Maddesi
                          <span class="required">*</span>
                        </h5>
                        <div class="row">
                          <div class="col-md-8">
                            <input type="text" class="form-control" v-model="subItem['name']" @keydown.enter="onEnter">
                          </div>
                          <div class="col-md-4" style="text-align:end">
                            <div class="uk-grid uk-grid-small d-flex align-items-center justify-content-between"
                              uk-grid>
                              <div class="d-flex align-items-center">
                                <div class="uk-width-auto">
                                  <label class="uk-switch mb-0">
                                    <input type="checkbox" v-model="subItem.is_mandatory">
                                    <div class="uk-switch-slider uk-switch-small"></div>
                                  </label>
                                </div>
                                <div class="uk-width-expand">
                                  <small class="ml-2">İşaretlenmesi zorunludur</small>
                                </div>
                              </div>
                              <button class="btn btn-light bg-light" uk-tooltip="title: Kaldır"
                                @click="removeCheckListItem(index)"><i class="uil-trash"></i></button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div>
                      <button class="uk-button uk-button-outline" type="button" style="width:100%"
                        @click="addCheckListItem()">
                        <i class="uil-plus"></i> Kontrol Ekle
                      </button>
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.mission'">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="form-group">
                          <h5 class="uk-text-bold mb-2">Görev Açıklaması</h5>
                          <textarea v-model="item.content_mission.mission_description" name="short_description"
                            id="short_description2" class="form-control"></textarea>
                        </div>
                        <!-- --------------------- İçerik Türü = Görev alanı ------------------------------------------ -->
                        <AnswerTypeSelector
                            :accepted_right_answer="item.content_mission.accepted_right_answer"
                            :completion_rule="item.content_mission.completion_rule"
                            :isAnswerTypeMission="item.content_mission.answer_type"
                            @update:answerType="item.content_mission.answer_type = $event"
                            @update:completion_rule="item.content_mission.completion_rule = $event"
                            @update:accepted_right_answer="item.content_mission.accepted_right_answer = $event"
                        />

                      </div>
                      <div class="col-md-5">
                        <h5 class="uk-text-bold mb-2">Görev Dosyası</h5>
                        <div class="d-flex align-items-center">
                          <div class="uk-width-auto">
                            <label class="uk-switch mb-0">
                              <input type="checkbox" v-model="item.content_mission.should_upload_mission_file">
                              <div class="uk-switch-slider uk-switch-small"></div>
                            </label>
                          </div>
                          <div class="uk-width-expand">
                            <small class="ml-2">Görev Dosyası Yüklensin</small>
                          </div>
                        </div>
                        <!-- <input type="file" class="d-none" id="mission_file">
                        <button type="button" class="btn btn-sm btn-outline-primary mt-4">
                          <i class="uil-upload-alt"></i> Dosya Yükle
                        </button> -->

                        <div class="uploadOuter" v-if="item.content_mission.should_upload_mission_file">
                          <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i> Dosya yükle</label>
                          <strong>{{ $t("general.or") }}</strong>
                          <span class="dragBox">
                            <i :class="selected_content_type.icon"></i>
                            Sürükle
                            <input type="file" :accept="acceptable_file_type" draggable="true" @dragover.prevent
                              @dragenter="drag" @dragleave="drop" id="uploadFile" @change="droppedMission" />
                          </span>
                          <img class="rounded img-fluid logoarea" :src="item.content_mission?.mission_file" alt="" />
                        </div>
                        <div id="previewMission"></div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.webinar'">
                    <!--webinar-form-->
                    <div>
                      <div class="row webinar-insert-form">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-3">
                              <h5 class="uk-text-bold mb-2">Webinar Türü</h5>
                              <multiselect :placeholder="$t('Webinar Türünü Seçiniz')" v-model="webinar_integration_id"
                                label="name" :options="webinar_types" track-by="id" :allow-empty="false"
                                :searchable="true" deselectLabel="" selectLabel="Seçiniz" />
                            </div>
                            <div class="col-md-3">
                              <h5 class="uk-text-bold mb-2">Tarih*</h5>
                              <VueCtkDateTimePicker :format="'YYYY-MM-DD HH:mm'" :no-button-now="true"
                                v-model="item.webinar_starts_at" />
                            </div>

                            <div class="col-md-3">
                              <h5 class="uk-text-bold mb-2">Saat Dilimi</h5>
                              <select name="webinar_timezone" id="webinar_timezone">
                                <option>Europe/Istanbul</option>
                                <!-- <option>(GMT + 3:00) Atina)</option>
                                <option>(GMT + 3:00) Bükreş)</option>
                                <option>(GMT + 3:00) Lefkoşa)</option>
                                <option>(GMT + 3:00) Helsinki)</option> -->
                              </select>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-4">
                              <h5 class="uk-text-bold mb-2">Güvenlik</h5>
                              <input name="webinar_security" id="webinar_security" type="text"
                                v-model="item.webinar_password" />
                              <small>Yalnızca davet baglantısına veya parolaya sahip kullanıcılar toplantıya
                                katılabilir.</small>
                            </div>
                            <!-- <div class="col-md-8 pt-4"> -->
                            <!-- <input type="checkbox" name="webinar_waitingroom" id="webinar_waitingroom"/> -->
                            <!-- <label for="webinar_waitingroom" class="ml-3">Bekleme Odası</label> -->
                            <!-- <small>Yalnızca oturum sahibi tarafından kabul edilen kullanıcılar toplantıya katılabilir.</small> -->
                            <!-- </div> -->
                          </div>
                          <div class="row mt-4">
                            <div class="col-md-12">
                              <!-- <p><input type="checkbox" name="webinar_option_1" id="webinar_option_1" class="mr-1"><label for="webinar_option_1" class="mr-1">Katılımcıların istediği zaman katılmasına izin ver.</label></p> -->
                              <!-- <p><input type="checkbox" name="webinar_option_2" id="webinar_option_2" class="mr-1"><label for="webinar_option_2">Girişte katılımcılaır sessize al.</label></p> -->
                              <p><input type="checkbox" name="webinar_option_3" id="webinar_option_3"
                                  v-model="item.webinar_is_auto_recording_enabled" class="mr-1"><label
                                  for="webinar_option_3">Toplantıyı
                                  otomatik olarak yerel
                                  bilgisayara kaydet.</label></p>
                              <!-- <p><input type="checkbox" name="webinar_option_4" id="webinar_option_4" class="mr-1"><label for="webinar_option_4">Belirli ülkelerden/bölgelerden gelen kullanıcılar için girişini onayla veya engelle.</label></p> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--END webinar-form-->
                  </div>
                  <div v-else-if="selected_content_type.key === 'documents.information_content'">
                    <div class="row" style="justify-content: center;">
                      <div class="form-group">

                        <vue-editor v-model="item.information_content"></vue-editor>
                      </div>

                    </div>
                  </div>
                  <div class="uploadOuter" v-else>
                    <label for="uploadFile" class="btn btn-info"><i class="uil-upload"></i> {{ $t("general.upload")
                      }}</label>
                    <strong>{{ $t("general.or") }}</strong>
                    <span class="dragBox">
                      <i :class="selected_content_type.icon"></i>
                      {{ $t(selected_content_type.key + "_drag_drop") }}
                      <input type="file" :accept="acceptable_file_type" draggable="true" @dragover.prevent
                        @dragenter="drag" @dragleave="drop" id="uploadFile" @change="dropped" />
                    </span>
                  </div>
                  <!-- <div class="preview col-md-2">
                      <a :href="item.file.file_link">Dosya</a>
                  </div> -->
                  <div id="preview" v-if="item.file">
                    <p>
                    <div class="file-info">
                      <div>Format: <span>{{ item.file.name.split(".").pop().toUpperCase() }}</span></div>
                      <div>Doküman Adı: <span> {{ item.file.name.toUpperCase() }} </span></div>
                      <div>İndirme Linki: <a :href="item.file.file_link">İndir</a></div>
                    </div>
                    </p>
                    <img v-if="item.file_category_id === 2" :src="item.file.file_link">
                  </div>
                  <div id="preview" v-if="item.sco?.length > 0">
                    <p>
                    <div class="file-info">
                      <div>UUID: <span>{{ item.sco.uuid }}</span></div>
                      <div>Doküman Adı: <span> {{ item.sco.title }}.zip </span></div>
                    </div>
                    </p>
                  </div>
                  <div v-if="selected_content_type.key === 'documents.powerpoint_document'" class="row">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Scorm Paketi Olarak
                        Ayarla</label></span>
                    <input v-model="is_powerpoint_scorm_file"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                  </div>
                  <div v-if="selected_content_type.key === 'documents.powerpoint_document'" class="row">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Süreli
                        Pptx</label></span>
                    <i class="uil-info-circle" style="margin-top:10px"
                      uk-tooltip="title: Tamamlama butonu geri sayım bitince aktif hale gelir"></i>
                    <input v-model="is_completion_timeout" type="checkbox"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                    <input v-if="is_completion_timeout" type="number" min="1" v-model="item.completion_timeout"
                      @keypress="isNumber($event)" style="width:6%" />
                    <span v-if="is_completion_timeout" style=" margin-top: 15px;margin-left: 10px;">{{
                      $t("general.minutes")
                    }}</span>
                  </div>
                  <div v-if="selected_content_type.key === 'documents.video_document'" class="row">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Video İleri
                        Sarılabilsin</label></span>
                    <input v-model="item.video_can_be_fast_forwarded" type="checkbox"
                      @input="videoBarChange('start', $event.target.checked)"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                    <span> <label class="col-form-label" style="margin-left:2rem;margin-top:10px">Video Tamamlandığında
                        İleri
                        Sarılabilsin</label></span>
                    <input v-model="item.video_is_completed_it_can_be_fast_forwarded" type="checkbox"
                      @input="videoBarChange('completed', $event.target.checked)"
                      style="width:2%;margin-top:12px;margin-right:1rem;margin-left:1rem">
                  </div>
                </div>
              </div>
              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(0)">
                  <i class="icon-feather-arrow-left"></i>Önceki
                </button>
                <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(2)">
                  <i class="icon-feather-arrow-right"></i> {{ this.$t('general.save_and_continue') }}
                </button>
              </div>
            </li>

            <!--tab: ayarlar-->
            <li>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <!-- <div class="form-group row mb-2">
                      <label
                        class="col-md-6 col-form-label"
                        for="website_keywords"
                        >İçerik Paylaşımı
                        <i
                          class="uil-info-circle"
                          uk-tooltip="title: lorem ipsum dolor sit amet"
                          title=""
                          aria-expanded="false"
                        ></i
                      ></label>
                      <div class="col-md-6">
                        <label class="uk-switch" for="content_share">
                          <input
                            type="checkbox"
                            id="content_share"
                            v-model="item.share_content"
                            @change="handleContentShareChanges"
                          />
                          <div class="uk-switch-slider"></div>
                        </label>
                      </div>
                    </div> -->
                    <!-- <div class="form-group row mb-2">
                      <label
                        class="col-md-6 col-form-label"
                        for="website_keywords"
                        >{{this.$t('general.experience_sharing')}}
                        <i
                          class="uil-info-circle"
                          uk-tooltip="title: lorem ipsum dolor sit amet"
                          title=""
                          aria-expanded="false"
                        ></i
                      ></label>
                      <div class="col-md-6">
                        <label class="uk-switch" for="experience_share">
                          <input
                            type="checkbox"
                            id="experience_share"
                            v-model="item.share_experience"
                          />
                          <div class="uk-switch-slider"></div>
                        </label>
                      </div>
                    </div> -->
                    <div class="form-group row mb-2">
                      <label class="col-md-4 col-form-label" for="website_keywords">Sertifika Seçimi
                        <i class="uil-info-circle"
                          uk-tooltip="title: Kullanıcılar eğitimi tamamladığında seçili sertifikaya sahip olabilirler"></i></label>
                      <div class="col-md-8">
                        <multiselect v-model="item.certificate" tag-placeholder="Sertifika Seç"
                          placeholder="Sertifika Seç" label="name" track-by="id" :options="certificate_items"
                          :allow-empty="true" :searchable="true" deselectLabel="" selectLabel="Seçiniz" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-card-footer float-right pb-0 pr-0">
                  <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(1)">
                    <i class="icon-feather-arrow-left"></i>Önceki
                  </button>
                  <button class="uk-button uk-button-primary small" type="button" @click="validateAndSwitch(3)">
                    <i class="icon-feather-arrow-right"></i>{{ this.$t('general.save_and_continue') }}
                  </button>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-12 my-lg-5">
                  <div class="text-center">
                    <h2 class="mt-0">
                      <i class="icon-feather-check-circle text-success"></i>
                    </h2>
                    <h3 class="mt-0">İçerik Hazır !</h3>

                    <div class="col-md-6 offset-md-3">
                      <table class="uk-table uk-table-divider result-table">
                        <tbody>
                          <tr>
                            <td class="text-center" colspan="2">
                              <img class="logoarea rounded img-fluid" :src="item.cover_photo_link" alt="" />
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">{{ this.$t('collectionDetail.content_name') }}:</td>
                            <td class="text-left">
                              <b>{{ title }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">İçerik Türü:</td>
                            <td class="text-left">
                              <b>{{ file_type_selected }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">Kategori Etiketleri:</td>
                            <td class="text-left">
                              <b>{{ hashtags_result }}</b>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-right">İçerik Paylaşımı:</td>
                            <td class="text-left">
                              <b>{{ content_share }}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <button class="btn btn-info">
                        <i class="uil-eye"></i> Önizleme
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-card-footer float-right pb-0 pr-0">
                <button class="uk-button uk-button-outline mr-2" type="button" @click="switcherChange(2)">
                  <i class="icon-feather-arrow-left"></i>Önceki
                </button>
                <button :class="assignments ? 'disabled' : ''" class="uk-button uk-button-primary small" type="button"
                  @click="saveForm()">
                  <i class="icon-feather-arrow-right"></i>             {{ $t("general.save")}}

                </button>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import Multiselect from "vue-multiselect";
import moment from "moment";
import axios from "axios";
import module, {
  GET_FILE_TYPES,
  GET_CONTENT_CATEGORIES,
  FILE_TYPES_URL,
  CONTENT_CATEGORIES_URL,
  MODULE_NAME,
  FILE_TYPES,
  CONTENT_CATEGORIES,
} from "@/core/services/store/content_categories.module";
import contentModule, {
  BASE_URL,
  MODULE_NAME as CONTENT_MODULE_NAME,
  UPDATE_ITEM,
  GET_ITEM,
  ITEM,
  ERROR,
  LOADING,
} from "@/core/services/store/content.module";

import certificate_module, {
  BASE_URL as CERTIFICATE_BASE_URL,
  GET_ITEMS as CERTIFICATE_GET_ITEMS,
  MODULE_NAME as CERTIFICATE_MODULE_NAME,
  ITEMS as CERTIFICATE_ITEMS,
} from "@/core/services/store/certificate.module";

import DefaultLoading from "@/view/components/loading/DefaultLoading";
import AnswerTypeSelector from "@/view/company/Content/components/AnswerTypeSelector.vue";
const _CERTIFICATE_MODULE = CERTIFICATE_MODULE_NAME;
import { VueEditor } from "vue2-editor";
export default {
  name: "ContentEdit",
  components: { Multiselect, DefaultLoading, VueEditor, AnswerTypeSelector },
  data() {
    return {
      is_completion_timeout: false,
      completion_timeout: 1,
      is_powerpoint_scorm_file: false,
      educationPlaces: [],
      instructorNames: [],
      hashtags: [],
      isClosedHashtagSelector: true,
      hashtags_options: [],
      title: "Başlık",
      hashtags_result: "Kategori Etiketleri Seçin",
      content_share: "Kapalı",
      file_type_selected: "İçerik Türünü Seçin",
      successMessage: "",
      errorMessages: [],
      acceptable_file_type: "*",
      selected_content_type: [],
      previewHTML: "",
      file: null,
      original_file_category_id: null,
      assignments: false,
      webinar_types: [{ id: 6, name: 'zoom' }],
      webinar_integration_id: { id: 6, name: 'zoom' },
      webinar_integrationType: null,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(_CERTIFICATE_MODULE, certificate_module);
    registerStoreModule(CONTENT_MODULE_NAME, contentModule);
  },
  computed: {
    loading() {
      return store.getters[CONTENT_MODULE_NAME + "/" + LOADING];
    },
    certificate_items: {
      get() {
        return store.getters[CERTIFICATE_MODULE_NAME + "/" + CERTIFICATE_ITEMS];
      },
      set(value) { },
    },
    formattedFileTypes: {
      get() {
        let results = [];
        if (this.file_types === null || this.item === null) return [];
        for (let i = 0; i < this.file_types?.length; i++) {
          let currentItem = this.file_types[i];
          if (currentItem) {
            let arr_formatted = {
              icon: currentItem.icon,
              name: currentItem.name,
              key: currentItem.key_name,
              accept: currentItem.accept,
              id: currentItem.id,
            };
            results.push(arr_formatted);
            if (
              currentItem.accept != "url" &&
              currentItem.accept != "id" &&
              this.original_file_category_id == currentItem.id
            )
              this.setPreviewFile(arr_formatted, this.item.file);
            if (currentItem.id == this.item.file_category_id) {
              this.selected_content_type = arr_formatted;
              this.acceptable_file_type = currentItem.accept;
              this.file_type_selected = this.$t(currentItem.key_name);
            }

          }
        }
        return results;
      },
      set(value) { },
    },
    formattedContentCategories: {
      get() {
        let results = [];
        if (this.content_categories === null) return [];
        for (let i = 0; i < this.content_categories?.length; i++) {
          let currentItem = this.content_categories[i];
          if (currentItem) {
            results.push({
              icon: currentItem.icon,
              name: currentItem.name,
              id: currentItem.id,
            });
          }
        }
        return results;
      },
      set(value) { },
    },
    file_types: {
      get() {
        return store.getters[MODULE_NAME + "/" + FILE_TYPES];
      },
      set(value) { },
    },
    content_categories: {
      get() {
        return store.getters[MODULE_NAME + "/" + CONTENT_CATEGORIES];
      },
      set(value) { },
    },
    item: {
      get() {
        return store.getters[CONTENT_MODULE_NAME + "/" + ITEM];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[CONTENT_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    videoBarChange(type, event) {
      if (type === 'start' && event) {
        this.item.video_is_completed_it_can_be_fast_forwarded = false;
      }
      else if (type === 'completed' && event)
        this.item.video_can_be_fast_forwarded = false;
    },
    onEnter(e) {
      e.stopPropagation()
      e.preventDefault()
    },
    removeCheckListItem(index) {
      this.item.content_check_list_items.splice(index, 1)
    },
    addCheckListItem() {
      this.item.content_check_list_items.push({ name: null, is_mandatory: false })
    },
    setHashtags() {
      axios
        .get(`/api/content-hashtags`, {
          headers: this.apiHeaders,
        })
        .then((response) => {
          this.hashtags_options = response.data;
        });
    },
    getCertificates() {
      this.$store.dispatch(CERTIFICATE_MODULE_NAME + "/" + CERTIFICATE_GET_ITEMS, {
        url: CERTIFICATE_BASE_URL,
        filters: {
          status_id: 1,
          order: "asc",
          sort: "id",
        },
      });
    },
    isEmptyHashtags() {
      return this.isClosedHashtagSelector && this.hashtags?.length === 0;
    },
    handleHashtagsSelected() {
      let data = JSON.parse(JSON.stringify(this.hashtags));
      if (data?.length > 0)
        this.hashtags_result = data
          .map(function (elem) {
            return elem.name;
          })
          .join(", ");
      else this.hashtags_result = "Kategori Etiketleri Seçin";
      this.item.hashtags = data.map(function (elem) {
        return elem.name;
      });
    },
    changeOption() {
      let id = this.form.file_category_id.id;
      if (id) {
        let ContentType = this.getContentTypeById(id);
        this.file_type_selected = ContentType.name;
        this.acceptable_file_type = ContentType.accept;
        this.selected_content_type = ContentType;
        if (this.selected_content_type.key === 'documents.classroom_education') {
          axios
            .get(`/api/content-classroom-education-hashtags`, {
              headers: this.apiHeaders,
            })
            .then((response) => {
              this.instructorNames = response.data.instructor_name;
              this.educationPlaces = response.data.education_place;
            });
        } else if (this.selected_content_type.key === 'documents.webinar') {
          axios
            .get(`/api/webinar-integrations`, {
              headers: this.apiHeaders,
            })
            .then((response) => {
              response.data.forEach((data) => {
                this.webinar_types.length === 0 ? this.webinar_types.push({ name: data.integration.name, id: data.id + '' }) : null
              })
            });
        }
        this.form.files = [];
        this.form.url = null;
        $("#preview").html("");
        $("#previewMission").html("");
        $("#uploadFile").val("");
      }
    },
    getContentTypeById(id) {
      let data;
      this.formattedFileTypes.forEach((item) => {
        if (item.id == id) {
          data = item;
        }
      });
      return data;
    },
    saveForm() {
      let item = this.item;
      if (!this.isValidFields("ALL") || this.assignments) return false;

      if (this.acceptable_file_type == "url") {
        if (!item.url.includes("http")) item.url = "https://" + item.url;
      }


      let formData = new FormData();
      if (this.selected_content_type.key === "documents.mission") {
        formData.append("mission_description", item.content_mission.mission_description);
        formData.append("answer_type", item.content_mission.answer_type);
        formData.append("completion_rule", item.content_mission.completion_rule);
        // formData.append("completion_rule", "answered");

        formData.append("mission_file", item.content_mission.mission_file);
        formData.append(
          "should_upload_mission_file",
          item.content_mission.should_upload_mission_file
        );
        formData.append("accepted_right_answer", item.content_mission.accepted_right_answer);
      }
      formData.append("title", item.title);
      formData.append("point", item.point);
      formData.append("duration", item.duration);
      formData.append("has_url", this.acceptable_file_type === "url" ? 1 : 0);
      formData.append("has_anzera_id", this.acceptable_file_type === "id" ? 1 : 0);
      formData.append("url", item.url);
      formData.append('use_content_point', (item.use_content_point === true || item.use_content_point === 1) ? 1 : 0)

      if (item.use_content_point) {
        formData.append("point", 0);
      } else {
        formData.append("point", item.point);
      }
      if (this.selected_content_type.key === "documents.webinar") {
        formData.append("webinar_starts_at", new Date(item.webinar_starts_at).toISOString().replace(/.\d+Z$/g, "Z"));
        formData.append("webinar_password", item.webinar_password);
        formData.append("webinar_is_auto_recording_enabled", item.webinar_is_auto_recording_enabled);
        formData.append("webinar_timezone", "Europe/Istanbul");
        formData.append("webinar_integration_id", item.webinar_integration_id);
      }
      if (this.selected_content_type.key === "documents.powerpoint_document") {
        formData.append("is_powerpoint_scorm_file", this.is_powerpoint_scorm_file);
        this.is_completion_timeout ? formData.append("completion_timeout", Number(this.completion_timeout)) : formData.append("completion_timeout", null);
      }
      formData.append("description", item.description);
      formData.append("content_category_id", item.content_category_id);
      formData.append("file_category_id", item.file_category_id);
      if (item.certificate) formData.append("certificate_id", item.certificate.id);
      item.information_content ? formData.append("information_content", item.information_content) : null;
      this.hashtags.forEach((item, index) => {
        formData.append("hashtags[" + index + "][id]", item.id);
        formData.append("hashtags[" + index + "][name]", item.name);
      });
      formData.append(
        "share_content",
        item.share_content == true || item.share_content == 1 ? 1 : 0
      );
      formData.append(
        "share_experience",
        item.share_experience == true || item.share_experience == 1 ? 1 : 0
      );

      formData.append("instructor_name", item.instructor_name);
      formData.append("accommodation_cost", item.accommodation_cost);
      formData.append("education_place", item.education_place);
      formData.append("education_cost", item.education_cost);
      formData.append("transportation_cost", item.transportation_cost);
      formData.append("education_place_cost", item.education_place_cost);
      formData.append("capacity", item.capacity);
      item.content_check_list_items.forEach((element, index) => {
        formData.append("content_check_list_items[" + index + "][name]", element.name);

        formData.append("content_check_list_items[" + index + "][is_mandatory]", element.is_mandatory === true ? 1 : 0);
        element.id ? formData.append("content_check_list_items[" + index + "][id]", element.id) : null
      });
      item.content_check_list_items[0]?.description ?
        formData.append("content_check_list_item_description", item.content_check_list_items[0].description) : null;

      if (item.cover_photo) formData.append("cover_photo", item.cover_photo);
      if (this.file) formData.append("file", this.file);
      store
        .dispatch(CONTENT_MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id + "/update",
          contents: formData,
        })
        .then(() => {
          $("html, body").animate({ scrollTop: 0 }, "slow");
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_updated");
            this.$router.push({ name: "company.contents.all" });
          } else this.errorMessages.push(this.error);
        });
    },
    validateURL(url) {
      var res = url.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      return res !== null;
    },
    handleContentShareChanges(e) {
      if (e.target.checked) {
        this.content_share = "Açık";
      } else this.content_share = "Kapalı";
    },
    handleTitleChanges(e) {
      this.title = e.target.value;
    },
    getContentCategories() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_CONTENT_CATEGORIES, {
        url: CONTENT_CATEGORIES_URL,
      });
    },
    getFileCategories() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_FILE_TYPES, {
        url: FILE_TYPES_URL,
        filters: { exam: true }
      });
    },
    getItem() {
      store
        .dispatch(CONTENT_MODULE_NAME + "/" + GET_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
        })
        .then((response) => {
          if (response?.content_category_id === 3) {
            axios.get(`/api/content-classroom-education-hashtags`, {
              headers: this.apiHeaders,
            })
              .then((response) => {
                this.instructorNames = response.data.instructor_name;
                this.educationPlaces = response.data.education_place;
              });
          } else if (response?.content_category_id === 2) {
            axios
              .get(`/api/webinar-integrations`, {
                headers: this.apiHeaders,
              })
              .then((response) => {
                // response.data.forEach((data) => {
                //   this.webinar_types.length === 0 ? this.webinar_types.push({ name: data.integration.name, id: data.id + '' }) : null

                // })
              });
          }
          this.updateFormValues();
        });
    },
    updateFormValues() {
      let item = this.item;
      this.title = item.title;
      !item.is_powerpoint_scorm_file ? this.is_completion_timeout = true : null;
      this.assignments = item.assignments_count > 0 ? true : item.program_section_contents_count
      this.original_file_category_id = item.file_category_id;
      if (item.share_content == 1) {
        this.content_share = "Açık";
      } else this.content_share = "Kapalı";
      item.hashtags.forEach((hashtag) => {
        this.addTag(hashtag.name, hashtag.id);
      });
      this.handleHashtagsSelected();
    },
    addInstructorName(event) {
      this.instructorNames.push(event);
      this.item.instructor_name = event;
    },
    addEducationPlace(event) {
      this.educationPlaces.push(event);
      this.item.education_place = event;
    },
    addTag(newTag, id = null) {
      const tag = {
        name: newTag,
        id:
          id ?? "custom_" + newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.hashtags_options.push(tag);
      this.hashtags.push(tag);
    },
    processFile(event) {
      this.item.cover_photo = event.target.files[0];
      let blob = URL.createObjectURL(this.item.cover_photo);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    handleContentFileChange(event) {
      this.file = event.target.files;
    },
    inputClick() {
      $("#logo").click();
    },
    dropped(event) {
      let file = event.target.files[0];
      if (!file) return false;
      if (!this.compareTypeCompatibality(file, this.acceptable_file_type)) {
        $("#uploadFile").val("");
        this.errorMessages.push("invalid file type");
        return false;
      }
      this.previewHTML = "";
      this.file = event.target.files[0];
      let fileName = URL.createObjectURL(event.target.files[0]);
      let preview = document.getElementById("preview");
      let previewImg = false;
      if (this.acceptable_file_type.includes("pdf"))
        previewImg = document.createElement("embed");
      if (this.acceptable_file_type.includes("image"))
        previewImg = document.createElement("img");
      if (file.type.includes("audio") && this.acceptable_file_type.includes("audio"))
        previewImg = document.createElement("audio");
      if (file.type.includes("video") && this.acceptable_file_type.includes("video"))
        previewImg = document.createElement("video");
      if (previewImg && previewImg.controls != undefined) previewImg.controls = true;
      let docInfo = document.createElement("p");
      docInfo.innerHTML = this.docInfo(file);
      if (previewImg) previewImg.setAttribute("src", fileName);
      if (previewImg && previewImg.tagName.toUpperCase() == "EMBED") {
        previewImg.style.width = "100%";
        previewImg.style.minHeight = "800px";
        previewImg.setAttribute("type", file.type);
      }
      preview.innerHTML = "";
      preview.appendChild(docInfo);
      if (previewImg) preview.appendChild(previewImg);
      this.drop(event);
    },
    setPreviewFile(file_category, file) {
      if (file_category == undefined || file == undefined) return false;
      let previewImg = false;
      if (file_category.accept.includes("image"))
        previewImg = document.createElement("img");
      if (file_category.accept.includes("audio"))
        previewImg = document.createElement("audio");
      if (file_category.accept.includes("video"))
        previewImg = document.createElement("video");
      if (previewImg && previewImg.controls != undefined) previewImg.controls = true;
      if (previewImg) previewImg.setAttribute("src", file.file_link);
      let previewHeader =
        '<div class="file-info"><div>Format: <span>' +
        file.name.split(".").pop().toUpperCase() +
        "</span></div><div>Doküman Adı: <span>" +
        file.name +
        "</span></div></div>";
      if (!previewImg) {
        this.previewHTML = previewHeader;
        return true;
      }
      this.previewHTML =
        previewHeader + '<div class="mt-3">' + previewImg.outerHTML + "</div>";
    },
    droppedMission(event) {
      let file = event.target.files[0];
      if (!file) return false;

      // if (!this.compareTypeCompatibality(file, this.acceptable_file_type)) {
      //   $("#uploadFile").val("");
      //   this.errorMessages.push("invalid file type");
      //   return false;
      // }
      this.item.content_mission.mission_file = event.target.files[0];
      let fileName = URL.createObjectURL(event.target.files[0]);
      let preview = document.getElementById("previewMission");
      let previewImg = false;
      if (this.acceptable_file_type.includes("pdf"))
        previewImg = document.createElement("embed");
      if (this.acceptable_file_type.includes("image"))
        previewImg = document.createElement("img");
      if (
        file.type.includes("audio") &&
        this.acceptable_file_type.includes("audio")
      )
        previewImg = document.createElement("audio");
      if (
        file.type.includes("video") &&
        this.acceptable_file_type.includes("video")
      )
        previewImg = document.createElement("video");
      if (previewImg && previewImg.controls != undefined)
        previewImg.controls = true;
      let docInfo = document.createElement("p");
      docInfo.innerHTML = this.docInfo(file);
      if (previewImg) previewImg.setAttribute("src", fileName);
      if (previewImg && previewImg.tagName.toUpperCase() == "EMBED") {
        previewImg.style.width = "100%";
        previewImg.style.minHeight = "800px";
        previewImg.setAttribute("type", file.type);
      }
      preview.innerHTML = "";
      preview.appendChild(docInfo);
      if (previewImg) preview.appendChild(previewImg);
      this.drop(event);
    },
    compareTypeCompatibality(file, acceptableType) {
      return (
        acceptableType.includes(file.type) ||
        acceptableType.includes(file.name.split(".").pop()) ||
        (acceptableType.split("/")[0] == file.type.split("/")[0] &&
          file.type.split("/")[0].toLowerCase() != "application")
      );
    },
    drag(event) {
      event.target.parentNode.className = "draging dragBox";
    },
    drop(event) {
      event.target.parentNode.className = "dragBox";
    },
    docInfo(file) {
      let file_complete_name = file.name;
      let file_name_split = file_complete_name.split(".");
      let file_extension = file_name_split.pop().toLowerCase();
      let file_name = file_name_split.join(".");
      let file_size = this.bytesToSize(file.size);
      return (
        '<div class="file-info"><div>Format: <span>' +
        file_extension.toUpperCase() +
        "</span></div><div>Doküman Adı: <span>" +
        file_name +
        "</span></div><div>Boyut: <span>" +
        file_size +
        "</span></div></div>"
      );
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
    isValidFields(fields) {
      let self = this;
      let form = self.item;
      self.resetMessages();

      let files = null;
      if (typeof form.files !== "undefined" && form.files?.length) files = form.files;

      if (fields === "ALL" || fields === 0) {
        if (form.title?.length === 0) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "{{this.$t('collectionDetail.content_name')}}")
          );
        }
        if (form.duration < 1) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Süre")
          );
        }
        if (!form.content_category_id) {
          return self.showFieldsFailedError(
            0,
            self.$t("validation.required_field").replace(":NAME", "Kategori")
          );
        }
        // if (form.hashtags?.length === 0) {
        //   return self.showFieldsFailedError(
        //     0,
        //     self.$t("validation.required_field").replace(":NAME", "Kategori Etiketleri")
        //   );
        // }
      }
      if (fields === "ALL" || fields === 1) {
        if (form.use_content_point === false && (form.point > 100 || form.point < 1)) {
          return self.showFieldsFailedError(
            1,
            self.$t("content.validation.score_between_1_100")
          );
        }

        if (!form.file_category_id) {
          return self.showFieldsFailedError(
            1,
            self.$t("validation.required_field").replace(":NAME", "İçerik Türü")
          );
        }
        if (
          self.acceptable_file_type == "url" &&
          (form.url == null || !self.validateURL(form.url))
        ) {
          return self.showFieldsFailedError(
            1,
            self
              .$t("validation.required_field")
              .replace(":NAME", self.$t(self.selected_content_type.key))
          );
        }
        if (self.acceptable_file_type !== "form" &&
          self.acceptable_file_type !== "url" &&
          self.acceptable_file_type !== "id" &&
          files != null &&
          (files?.length === 0 ||
            !self.compareTypeCompatibality(files, self.acceptable_file_type))
        ) {
          return self.showFieldsFailedError(
            1,
            self.$t("validation.required_field").replace(":NAME", "URL")
          );
        }
        if (self.acceptable_file_type === "id" && form.url == null) {
          return self.showFieldsFailedError(
            1,
            self.$t("validation.required_field").replace(":NAME", "Anzera Eğitimi ID")
          );
        }
        // Sınıf içi eğitim
        if (this.acceptable_file_type === "form" &&
          this.selected_content_type.key === 'documents.classroom_education') {
          if (form.instructor_name === null) {
            return self.showFieldsFailedError(
              1, self.$t("validation.required_field").replace(":NAME", "Eğitmen Adı")
            );
          }

          if (form.education_place === null) {
            return self.showFieldsFailedError(
              1, self.$t("validation.required_field").replace(":NAME", "Eğitim Yeri")
            );
          }
        }
        if (this.selected_content_type.key === 'documents.information_content' && (form.information_content === '' || !form.information_content)) {
          return self.showFieldsFailedError(
            1, self.$t("validation.required_field").replace(":NAME", "Bilgi İçeriği")
          );
        }
        else if (this.selected_content_type.key === 'documents.check_list_item') {
          if (form.content_check_list_items[0].description === '' || !form.content_check_list_items[0].description) {
            return self.showFieldsFailedError(
              1, self.$t("validation.required_field").replace(":NAME", "Açıklama Metni")
            );
          }
          let flag = true
          form.content_check_list_items.every((data => {
            if (!data.name || data.name === '') {
              flag = false;
              return false
            } else {
              return true
            }
          }));
          if (!flag) {
            return self.showFieldsFailedError(
              1, self.$t("validation.required_field").replace(":NAME", "Kontrol Listesi Maddeleri")
            );
          }
        }
      }

      return true;
    },
    showFieldsFailedError(index, message) {
      this.errorMessages.push(message);
      this.scrollToTop();
      this.switcherChange(index);
      return false;
    },
    validateAndSwitch(index) {

      if (this.isValidFields(index - 1)) this.switcherChange(index);
    },
    switcherChange(index) {
      UIkit.switcher($("#uk-switcher-tab")[0]).show(index);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getCertificates();
    this.getContentCategories();
    this.getFileCategories();
    this.getItem();
    this.setHashtags();
  },
  watch: {
    /*    'item.file_category_id': function (value) {
      if (value && value.id !== 1) {
        this.item.use_content_point = false;
      }
    },
    'item.use_content_point': function (value) {
      if (value) {
        this.item.point = 0;
      }
    },*/
    formattedFileTypes(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpickerFileCategory").selectpicker();
        }, 500);
      }
    },
    formattedContentCategories(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpickerContentCategory").selectpicker();
        }, 500);
      }
    },
    hashtags() {
      this.handleHashtagsSelected();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2500);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.dragBox {
  width: auto;
  padding: 0 10px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.uploadOuter strong {
  padding: 0 10px;
}

.uploadOuter {
  text-align: center;
  padding: 20px;
}

.uk-button.disabled {
  opacity: .5;

}
</style>
